import React, { useMemo } from "react";
import styles from "./style.module.scss";

const Countdown = ({ prev, count, label }) => {
  const padCount = useMemo(() => {
    return String(count).padStart(2, "0");
  }, [count]);

  return (
    <div className={`${styles.countdown}`}>
      <div>{padCount}</div>
      <p className={`${styles.label} fw-bold mb-2`}>{label}</p>
    </div>
  );
};

export default Countdown;
