import React from "react";
import Countdown from "../../components/Countdown";
import { useTranslation } from "next-i18next";

const LaunchCountdown = ({ days, hours, minutes, seconds }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Countdown prev={0} count={days} label={t("days")} />
      <Countdown prev={0} count={hours} label={t("hours")} />
      <Countdown prev={0} count={minutes} label={t("minutes")} />
      {/* <Countdown prev={0} count={seconds} label={t("seconds")} /> */}
    </>
  );
};

export default LaunchCountdown;
