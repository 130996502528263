import styles from "@styles/Home.module.scss";
import { useRegionInfo } from "@services/apiHooks";
import { useRouter } from "next/router";
import Clock from "react-live-clock";
import moment from "moment";
import { useTranslation } from "next-i18next";

const defaultProps = {
  layout: "primary",
};

let currentAnimation;
const WeatherWidget = (props) => {
  const { layout = "primary" } = props;
  const router = useRouter();
  const { t } = useTranslation("common");
  const { weather } = useRegionInfo(router.locale);

  const weatherInfo = {
    phrase: weather?.results[0].phrase,
    iconCode: weather?.results[0].iconCode,
    temperature: Math.round(weather?.results[0].temperature.value),
    relativeHumidity: Math.round(weather?.results[0].relativeHumidity),
    wind: Math.round(weather?.results[0].wind.speed.value),
  };

  const { phrase, iconCode, temperature, relativeHumidity, wind } = weatherInfo;

  switch (iconCode) {
    case 1: /* Sunny */
    case 2 /* Mostly Sunny */:
      currentAnimation = "/assets/lottie/sun.json";
      break;

    case 3: /* Partly Sunny */
    case 4: /* Intermittent Clouds */
    case 5 /* Hazy Sunshine */:
      currentAnimation = "/assets/lottie/sun-and-cloud.json";
      break;

    case 6 /* Mostly Cloudy */:
    case 7: /* Cloudy */
    case 8: /* Dreary */
    case 11 /* Fog */:
      currentAnimation = "/assets/lottie/cloud.json";
      break;

    case 12: /* Showers */
    case 13: /* Mostly Cloudy with Showers */
    case 14: /* Partly Sunny with Showers */
    case 18 /* Rain */:
      currentAnimation = "/assets/lottie/showers.json";

    default:
      currentAnimation = "/assets/lottie/sun-and-cloud.json";
  }

  const currentDate = moment
    .tz(moment(), "UTC")
    .tz(moment.tz.guess())
    .format("ddd, MMMM D");

  if (layout === "secondary")
    return (
      <div className="text-sm-end text-center my-5 weather">
        <div className="py-3 bg-white d-inline-flex flex-sm-row flex-column px-sm-5 px-3">
          <div className="d-inline-flex align-items-center">
            <div>
              <span className="greyText ff-graphic fw-bold f-11">
                {t("country-weather")}
              </span>
              <h2 className="text-orange f-35 ff-qatar2022 fw-bold mb-0">{`${temperature}°C`}</h2>
              <h6 className="greyText ff-graphic fw-regular f-11">{phrase}</h6>
            </div>
            <div
              className="mx-3 weather d-flex flex-column align-items-center Home_lottie__tJWwY"
              style={{ width: "90px" }}
            >
              <lottie-interactive
                path={currentAnimation}
                interaction="play-on-show"
                speed="1"
                delay="0"
                aspect-ratio="xMidYMid slice"
              />
            </div>
            <div className="d-flex  flex-column justify-content-center ">
              <div className="d-flex align-items-center mb-2">
                <img
                  src="/assets/svg/Icon weather-raindrop.svg"
                  alt="temperature-logo"
                />
                <p className="ml-3 mb-0 ps-2 greyText ff-graphic fw-regular f-14 ">
                  {`${relativeHumidity}%`}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <img
                  src="/assets/svg/Icon feather-wind.svg"
                  alt="feather-wind-logo"
                />
                <p className="ml-3 mb-0 ps-2 greyText ff-graphic fw-regular f-14 ">
                  {`${wind} Km/h`}
                </p>
              </div>
            </div>
          </div>
          <div className="lineBookingSeparator d-sm-block d-none"></div>
          <div className="text-center justify-content-center d-flex flex-column">
            <h3 className="text-orange ff-qatar2022 fw-bold f-35 mb-0">
              <Clock
                format={"h:mm:ss a"}
                ticking={true}
                timezone={moment.tz.guess()}
              />
            </h3>
            <p className="greyText ff-graphic fw-bold mb-0 f-11">
              {currentDate}
            </p>
          </div>
        </div>
      </div>
    );

  if (layout === "primary")
    return (
      <section className={`${styles.temp} bg-burgundy py-4 text-white`}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center">
            <div className={`${styles.arrowsTemp}`}>
              <img src="/assets/svg/line-left.svg" alt="line-left" />
            </div>
            {weather && (
              <div className="d-flex align-items-center mx-md-5">
                <div className="d-flex align-items-end flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <img
                      src="/assets/svg/temperature.svg"
                      alt="temperature-logo"
                    />
                    <p className="ml-3 mb-0 ps-2">{`${relativeHumidity}%`}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/svg/feather-wind.svg"
                      alt="feather-wind-logo"
                    />
                    <p className="ml-3 mb-0 ps-2">{`${wind} Km/h`}</p>
                  </div>
                </div>
                <div className="ms-4 me-sm-2">
                  <span>{t("country-weather")}</span>
                  <h5>{`${temperature}°C`}</h5>
                </div>
                <div
                  className={`weather d-flex flex-column align-items-center ${styles.lottie}`}
                >
                  <lottie-interactive
                    path={currentAnimation}
                    interaction="play-on-show"
                  />
                  <h6>{phrase}</h6>
                </div>
              </div>
            )}
            <div className={`${styles.arrowsTemp}`}>
              <img src="/assets/svg/line-right.svg" alt="line-right-logo" />
            </div>
          </div>
        </div>
      </section>
    );
};

WeatherWidget.defaultProps = defaultProps;

export default WeatherWidget;
