import Image from "next/image";
import React, { useRef, useState } from "react";
import CountdownBar from "../CountdownBar";
import SocialMedia from "../SocialMedia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styles from "../../styles/Home.module.scss";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import moment from "moment";
import {
  useUserInfo,
  useNewsInfo,
  useRegionInfo,
} from "../../services/apiHooks";
import { useIsAuthenticated } from "@azure/msal-react";
import WeatherWidget from "@components/weather/WeatherWidget";

const slickSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

let currentAnimation;

export default function HomePageLoggedIn(props) {
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);

  const { personal_info: personal, organisation } = user ?? {};

  const { first_name, last_name } = personal ?? {};

  const { weather } = useRegionInfo(router.locale);
  const { news } = useNewsInfo(router.locale);
  const { t } = useTranslation("common");
  const [mouseMoved, setMouseMoved] = useState(false);
  const sliderRef = useRef();

  React.useEffect(() => {
    import("lottie-interactive/dist/lottie-interactive.js");
  });

  const onNewsClick = (link) => {
    if (!mouseMoved) window.open(link);
  };

  const weatherInfo = {
    phrase: weather?.results[0].phrase,
    iconCode: weather?.results[0].iconCode,
    temperature: Math.round(weather?.results[0].temperature.value),
    relativeHumidity: Math.round(weather?.results[0].relativeHumidity),
    wind: Math.round(weather?.results[0].wind.speed.value),
  };

  const { phrase, iconCode, temperature, relativeHumidity, wind } = weatherInfo;

  switch (iconCode) {
    case 1: /* Sunny */
    case 2 /* Mostly Sunny */:
      currentAnimation = "/assets/lottie/sun.json";
      break;

    case 3: /* Partly Sunny */
    case 4: /* Intermittent Clouds */
    case 5 /* Hazy Sunshine */:
      currentAnimation = "/assets/lottie/sun-and-cloud.json";
      break;

    case 6 /* Mostly Cloudy */:
    case 7: /* Cloudy */
    case 8: /* Dreary */
    case 11 /* Fog */:
      currentAnimation = "/assets/lottie/cloud.json";
      break;

    case 12: /* Showers */
    case 13: /* Mostly Cloudy with Showers */
    case 14: /* Partly Sunny with Showers */
    case 18 /* Rain */:
      currentAnimation = "/assets/lottie/showers.json";

    default:
      currentAnimation = "/assets/lottie/sun-and-cloud.json";
  }

  return (
    <>
      <section className={`${styles.banner} banner d-flex align-items-center`}>
        <div className="d-none d-md-block">
          <Image
            src="/assets/images/gridimage/banner.jpg"
            alt="home-img"
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className="d-md-none">
          <Image
            src="/assets/images/banners/mobile/home.jpg"
            alt="home-img"
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className="container position-relative my-auto">
          <div className="welcomeSignin">
            <h2 className="text-white mb-4">
              {t("hihp")}, {`${first_name} ${last_name}`}
            </h2>
            <h1 className="text-white"> {t("welcomehp")}</h1>
            <h1 className="text-white"> {t("subwelcomehp")}</h1>
          </div>
        </div>
      </section>
      <section className={`gridImage `}>
        <div className="container-fluid">
          <div className="row border-yellow">
            <div className="col-sm-4 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image1.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white">{t("hostcountryhp")}</h2>
                <div className={`seeLocation `}>
                  <a
                    href="/media-services/host-country-media-accreditation"
                    target="_blank"
                  >
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image2.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white"> {t("accomodationhp")}</h2>
                <div className={`seeLocation `}>
                  <a
                    href="/media-services/host-country-media-accomodation"
                    target="_blank"
                  >
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image3.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white"> {t("hcmediacenterhp")}</h2>
                <div className={`seeLocation `}>
                  <a
                    href="/media-services/host-country-media-centre"
                    target="_blank"
                  >
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row border-yellow">
            <div className="col-sm-8 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image4.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white"> {t("officialhp")}</h2>
                <div className={`seeLocation `}>
                  <a href="/news-services/official-venues" target="_blank">
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image5.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white"> {t("tournamenthp")}</h2>
                <div className={`seeLocation `}>
                  <a href="/news-services/general-insights" target="_blank">
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row border-yellow">
            <div className="col-sm-4 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image6.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white">{t("hwchp")}</h2>
                <div className={`seeLocation `}>
                  <a
                    href="/news-services/fwc-2022-fixtures-results"
                    target="_blank"
                  >
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image7.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white">{t("culturalhp")}</h2>
                <div className={`seeLocation `}>
                  <a href="/news-services/general-insights" target="_blank">
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row border-yellow">
            <div className="col-sm-6 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image8.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white"> {t("nonofficialhp")}</h2>
                <div className={`seeLocation `}>
                  <a href="/news-services/non-official-venues" target="_blank">
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12 position-relative">
              <img
                src="/assets/images/gridimage/image9.jpg"
                className="w-100 h-100"
              />
              <div className="position-absolute align-items-center justify-content-center top-0 h-100 w-100">
                <h2 className="p-5 text-white">{t("entryqatarhp")}</h2>
                <div className={`seeLocation `}>
                  <a
                    href="/media-services/entry-to-qatar-and-covid-19-policy"
                    target="_blank"
                  >
                    {t("DiscoverMorehp")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SocialMedia
        Color="#8a1538"
        colNumber="col-md-4 col-12"
        font="ff-qatar2022 fs-5"
        fontWeight="fw-medium"
        textColor="text-burgundy"
        background="bg-light-grey"
        display="flex-row"
      />
      <CountdownBar />
      <section className={`news bg-light-grey  pt-4 pb-5`}>
        <div className="container position-relative ">
          <div className="d-flex justify-content-between ">
            <h1 className="pt-5 text-burgundy fw-bold ff-qatar2022">
              {t("newstitle")}
            </h1>
            <div className="my-md-3 position-absolute end-0 me-3 ms-ar-3 start-ar-0">
              <div className={`${styles.lottieWrapper}`}>
                <lottie-interactive
                  path="/assets/lottie/circle-and-arrow1.json"
                  interaction="play-on-show"
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <a
              href={t("seemorelink")}
              target="_blank"
              className="ms-auto me-ar-auto text-orange small fw-medium ff-graphik me-2"
            >
              {t("seemore")}
            </a>
            <div className={`customNavigation mb-3`}>
              <button
                type="button"
                data-role="none"
                className={` customArrow prev`}
                onClick={() => sliderRef?.current.slickPrev()}
              >
                Previous
              </button>
              <button
                type="button"
                data-role="none"
                className={`customArrow next`}
                onClick={() => sliderRef?.current.slickNext()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div>
          <Slider {...slickSettings} ref={sliderRef}>
            {news?.map(({ id, title, author, link, image, date }) => (
              <div
                key={id}
                className={` slide position-relative`}
                onMouseMove={() => setMouseMoved(true)}
                onMouseDown={() => setMouseMoved(false)}
                onMouseUp={onNewsClick.bind(this, link)}
              >
                {/* <img src={image} alt="news-a-img" /> */}
                <Image
                  alt="news-a-img"
                  src={image}
                  width={631}
                  height={631}
                  layout="responsive"
                />

                <div className={`contentText`}>
                  <h3>{title}</h3>
                  <p>{author}</p>
                  <p className={`dateNews newstitle`}>
                    {moment(date).format("DD MMMM YYYY")}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <WeatherWidget />
    </>
  );
}
