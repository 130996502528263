import React, { Fragment } from "react";
import Layout from "../components/Layouts";
import Nav from "../components/Navi";
import Footer from "../components/Footers";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import HomePage from "../components/Home/HomePage";
import HomePageLoggedIn from "../components/Home/HomePageLoggedIn";
import { useUserInfo } from "../services/apiHooks";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";

export default function Index(props) {
  const isAuthenticated = useIsAuthenticated();
  const { user, isUserInfoLoading, isUserInfoError } =
    useUserInfo(isAuthenticated);

  const { status_level, status, hayya_status, accreditation_id } =
    user?.personal_info ?? {};

  const isTester = user?.role === "TESTER";

  const isScApproved =
    status_level === "SC" && status === "approved" && accreditation_id === 1;

  const isHayaApproved =
    status_level === "HAYYA" &&
    status === "approved" &&
    hayya_status === "Approved" &&
    accreditation_id !== 1;

  return (
    <>
      <AuthenticatedTemplate>
        {user && (
          <Layout>
            <Nav />
            {/* old Status */}
            {/* <Fragment>
              {user?.personal_info?.status === "approved" ||
              user?.personal_info?.status === "approved_lusail" ? (
                <HomePageLoggedIn />
              ) : (
                <HomePage />
              )}
            </Fragment> */}
            <Fragment>
              {isTester || isScApproved || isHayaApproved ? (
                <HomePageLoggedIn />
              ) : (
                <HomePage />
              )}
            </Fragment>
            <Footer />
          </Layout>
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Layout>
          <Nav />
          <HomePage />
          <Footer />
        </Layout>
      </UnauthenticatedTemplate>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
