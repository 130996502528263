import Lottie from "react-lottie";
import LaunchCountdown from "../containers/LaunchCountdown";
import animationData from "../public/assets/lottie/globe.json";
import { useTranslation } from "next-i18next";
import useCountDown from "../hooks/useCountDown";

export default function Countdown({ hasBg = true }) {
  const [days, hours, minutes, seconds] = useCountDown(
    new Date("2022-12-18T17:00")
  );

  const { t } = useTranslation("common");
  let addClass = "";
  if (hasBg == true) addClass = "has-arrow has-diamonds bg-burgundy";
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className={`countdown ` + addClass}>
      <div className="container py-sm-3 py-5">
        <div className="row align-items-center g-0">
          <div className="col-lg-5 text-lg-end text-center">
            <h3 className="fw-bold ff-qatar2022 text-white me-lg-n4 mb-0">
              {t("countdownTitle")}
            </h3>
          </div>
          <div className="col-lg-2 text-center">
            <Lottie options={lottieOptions} width={75} />

            <img
              className="mt-2 hublot mw-100 d-none d-lg-inline-block"
              src="/assets/svg/hublot.svg"
              width={126}
              alt="hublot"
            />
          </div>
          <div className="col-lg-5 my-3 my-lg-0">
            <div className="d-flex justify-content-lg-start justify-content-center ms-lg-n4 pt-3">
              <LaunchCountdown
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
              />
            </div>
          </div>
          <div className="col-12 text-center d-lg-none">
            <img
              className="mt-2 hublot mw-100"
              src="/assets/svg/hublot.svg"
              width={75}
              alt="hublot"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
